import React from "react";
import { stackList } from "../../data/ProjectData";
import {
  Image,
  Technologies,
  Tech,
  TechImg,
  TechName,
  ContactWrapper,
} from "./AboutElements";
import ScrollAnimation from "react-animate-on-scroll";
function About() {
  return (
    <ContactWrapper id="about">
      <div className="Container">
        <div className="SectionTitle">About Me</div>
        <div className="BigCard">
          <ScrollAnimation animateIn="fadeInLeft">
            <Image src="/fotoabout.png" alt="" />
          </ScrollAnimation>
          <div className="AboutBio">
            <ScrollAnimation animateIn="fadeInLeft">
              Hola! Mi nombre es <strong>Vasconcel Diego</strong>. Durante mis
              estudios como Analista de Sistemas, me interesé en abordar temas
              de seguridad en línea, específicamente la prevención del grooming.
              Actualmente trabajo en el Instituto de Políticas Públicas de
              Prevención de Grooming de la Honorable Camára de diputados de la
              Prov. de Buenos Aires.
            </ScrollAnimation>

            <br />
            <br />

            <ScrollAnimation animateIn="fadeInLeft">
              Mi trayectoria en el mundo de la tecnología comenzó con una fuerte
              pasión por la resolución de problemas y en la investigacion
              digital lo que me llevó a seguir estudiando. A lo largo de mis
              estudios, tuve la oportunidad de participar en proyectos
              fascinantes. Destacablemente, contribuí en el desarrollo de la
              aplicación Emma y EchoFind, proyectos que recibieron
              reconocimiento tanto de mis colegas, instructores y docentes,
              sobre la ayuda inmedianta en la desaparición de un menor de edad.
            </ScrollAnimation>

            <br />
            <br />

            <ScrollAnimation animateIn="fadeInLeft">
              En el Instituto de Políticas Públicas de Prevención de Grooming,
              realizo capacitacitaciones y charlas abiertas hacia la comunidad,
              alumnos y docentes, trabajo realizando reportes de material de
              abuso sexual infantil a las plataformas Twitter y META,{" "}
              <strong>
                en donde demuestro como se establece la comunicacion entre
                pedofilos en internet y utilizan como Medio a las plataformas
                para lograr la distribución de dicho material.
              </strong>
              <div className="tagline2">
                Suelo usar las siguentes tecnologías:
              </div>
            </ScrollAnimation>

            <Technologies>
              {stackList.map((stack, index) => (
                <ScrollAnimation animateIn="fadeInLeft" key={index}>
                  <Tech key={index} className="tech">
                    <TechImg src={stack.img} alt={stack.name} />
                    <TechName>{stack.name}</TechName>
                  </Tech>
                </ScrollAnimation>
              ))}
            </Technologies>
          </div>
        </div>
      </div>
    </ContactWrapper>
  );
}

export default About;
