// --------------------------------------- Project List
export const ProjectList = [
  {
    img: "/echofind-responsive.png",
    title: "ECHOFIND - Landing Page",
    description:
      "Landing page - Echofind, una plataforma para poder entregar huella biometrica de un niño, niña o adolescente para establecer una busqueda rapida y eficaz.",
    tech_stack: ["React", "CSS", "TailwindCSS"],
    github_url: "",
    demo_url: "https://echofind.vasconceldiego.com/#home",
  },
  {
    img: "/socialmedia-lifetruth.png",
    title: "Lifetruth - Social Media.",
    description:
      "LifeTruth Crypto es una innovadora plataforma de redes sociales que se centra en la divulgación y discusión de criptomonedas. Conecta a entusiastas y expertos en criptografía de todo el mundo",
    tech_stack: ["React", "TailwindCSS", "Firebase"],
    github_url: "",
    progreso_url: ".",
    demo_url: "",
  },
  {
    img: "/emma-ipppg.png",
    title: "Emma - Asistencia a los NNyA.",
    description:
      "La aplicación EMMA brindara asistencia a NNyA o familiares victimas del delito de Grooming, Actualmente trabajando en conjunto con el Instituto de Politicas Publicas de Prevención de Grooming de la Honorable cámara de diputados de la provincia de Buenos Aires acompañado por la ONG Mamá en línea.",
    tech_stack: ["HTML", "CSS", "JS", "PHP", "Python"],
    github_url: "",
    demo_url:
      "https://www.canva.com/design/DAFXDXoVj8o/U0GxXwHmMBV4bBe7g_kzew/view?utm_content=DAFXDXoVj8o&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink",
  },
  {
    img: "/criptobook.png",
    title: "Blockchain y el futuro de las transferencias.",
    description:
      "Es un libro conciso e informativo que explora el potencial revolucionario de la tecnología blockchain en el ámbito de las transferencias de valor. Descubre cómo esta tecnología descentralizada, transparente y segura está transformando los procesos de transferencia de dinero.",
    tech_stack: [],
    github_url: "",
    libro_url:
      "https://www.canva.com/design/DAFQy3nUVgw/MYOp6vSU48MMEVooa7geug/view?utm_content=DAFQy3nUVgw&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink#1",
    demo_url: "",
  },
];

// --------------------------------------- Skills

export const stackList = [
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/html5/html5-original.svg",
    name: "HTML",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/css3/css3-original.svg",
    name: "CSS",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/javascript/javascript-original.svg",
    name: "JavaScript",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/react/react-original.svg",
    name: "ReactJS",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/bootstrap/bootstrap-original.svg",
    name: "Bootstrap",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/git/git-original.svg",
    name: "Git",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/react/react-original.svg",
    name: "React Native",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/nodejs/nodejs-original.svg",
    name: "NodeJS",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/firebase/firebase-plain.svg",
    name: "Firebase",
  },

  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/python/python-original.svg",
    name: "Python",
  },

  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/mysql/mysql-original.svg",
    name: "MySQL",
  },

  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/vscode/vscode-original.svg",
    name: "VS Code",
  },
];
