import React, { useState } from "react";
import Dropdown from "../Dropdown/Dropdown";
import Header from "../Header/Header";
import {
  HeroContainer,
  HeroWrapper,
  HeroLeft,
  HeroRight,
  Image,
  ScrollDown,
  ScrollLink,
} from "./HeroElements";
import { TypeAnimation } from "react-type-animation";
import ScrollAnimation from "react-animate-on-scroll";

function Hero() {
  const [isOpen, setIsOpen] = useState(false);
  const [showSubtitle, setShowSubtitle] = useState(false);
  const [showScrollDown, setShowScrollDown] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  return (
    <main>
      <Dropdown isOpen={isOpen} toggle={toggle} />
      <Header toggle={toggle} />
      <HeroContainer>
        <HeroWrapper>
          <HeroLeft>
            <ScrollAnimation animateIn="fadeIn">
              <TypeAnimation
                cursor={false}
                sequence={["Vasconcel Diego 👋", () => setShowSubtitle(true)]}
                speed={{ type: "keyStrokeDelayInMs", value: 70 }}
                wrapper="h1"
                repeat={0}
              />
              {showSubtitle && (
                <TypeAnimation
                  cursor={true}
                  sequence={[
                    500,
                    "OSINT - Digital Research.",
                    1000,
                    "SOCMINT - Intelligence from publicly available social media data.",
                    1000,
                    "Systems Analyst.",
                    () => setShowScrollDown(true),
                    500,
                    ":)",
                    500,
                  ]}
                  speed={50}
                  deletionSpeed={65}
                  wrapper="h5"
                  repeat={Infinity}
                />
              )}
              {showScrollDown && (
                <ScrollAnimation
                  animateIn="flipInX"
                  offset={0}
                  style={{ padding: "0px", marginTop: "50px" }}
                >
                  <ScrollDown to="projects" id="scrollDown">
                    <ScrollLink>
                      Scroll down
                      <img src="/scroll-down.svg" alt="scroll-down" />
                    </ScrollLink>
                  </ScrollDown>
                </ScrollAnimation>
              )}
            </ScrollAnimation>
          </HeroLeft>
          <HeroRight>
            <ScrollAnimation animateIn="fadeIn">
              <Image src="/Vasconceldiego.png" alt="man-svgrepo" />
            </ScrollAnimation>
          </HeroRight>
        </HeroWrapper>
      </HeroContainer>
    </main>
  );
}

export default Hero;
